export const ROUTES = {
  LOGIN: '/',
  ADMIN: {
    USERS: {
      LIST: '/users/overview',
    },
    CLIENTS: {
      LIST: '/admin/clients',
      MAILER: {
        LIST: '/admin/clients/mailer',
        CREATE: '/admin/clients/mailer/create',
        EDIT: '/admin/clients/mailer/edit',
        GENERATE: '/admin/clients/mailer/generate',
      },
      CREATE: '/admin/clients/create',
      CATEGORY: '/admin/clients/category',
      BUCKETS: {
        LIST: '/admin/clients/category/buckets/list',
        ADD: '/admin/clients/buckets/add',
        UPDATE: '/admin/clients/buckets/update',
      },
      DETAILS: '/admin/clients/details',
    },
    EDITKEYWORDS: '/admin/clients/edit-keywords',
    MANAGEPLAN: '/admin/clients/manage-plan',
    CORPUS: {
      CLIENT: '/admin/corpus/download-by-client',
      KEYWORD: '/admin/corpus/download-by-keyword',
    },
    MAILER: {
      TEMPLATES: {
        LIST: '/admin/clients/mailer-templates',
        CREATE: '/admin/clients/mailer-templates/create',
        EDIT: '/admin/clients/mailer-templates/edit',
      },
    },
  },
  ONLINE: {
    URLCHECKER: '/online/url-checker',
    LIST: '/online-news-and-blogs/list',
    CREATE: '/online-news-and-blogs/list/create',
    EDIT: '/online-news-and-blogs/list/edit',
  },
  PUBLICATIONS: {
    LIST: {
      LIST: '/publications/list',
      CREATE: '/publications/list/create',
      EDIT: '/publications/list/edit',
      PROFILE: '/publications/profile',
    },
    TIME_SLOTS: '/publications/time-slot',
  },
  PRINT: {
    DOWNLOAD: '/download-prints/overview',
    PRINTMEDIA: {
      LIST: '/print-media/overview',
      VERIFY: '/print-media/overview/verify',
      EDIT: '/print-media/overview/edit',
    },
    EDIT: '/print/edit',
    ADS: {
      LIST: '/print-ads/overview',
      VERIFY: '/print-ads/overview/verify',
      EDIT: '/print-ads/overview/edit',
    },
  },
  RADIO: {
    TIMESLOT: {
      LIST: '/radio/station-timeslot',
    },
    PROGRAMLIST: {
      LIST: '/radio/program-list',
      // CREATE: '/radio/radio-clips/create',
      // EDIT: '/radio/radio-clips/edit',
    },
    // new add
    PROGRAMSETUP: {
      LIST: '/radio/program-setup',
      CREATE: '/radio/program-setup/create',
      EDIT: '/radio/program-setup/edit',
    },
    RADIOCLIPS: {
      LIST: '/radio/radio-clips',
      CREATE: '/radio/radio-clips/create',
      EDIT: '/radio/radio-clips/edit',
    },
    RADIOAD: {
      LIST: '/radio-ads/overview',
      CREATE: '/radio-ads/overview/create',
      EDIT: '/radio-ads/overview/edit',
    },
  },
  TV: {
    CONTROL: {
      LIST: '/TV/control',
    },
    TIMESLOT: {
      LIST: '/TV/channel-timeslot',
    },
    PROGRAMSETUP: {
      LIST: '/TV/program-setup',
      CREATE: '/TV/program-setup/create',
      EDIT: '/TV/program-setup/edit',
    },
    PROGRAMLIST: {
      LIST: '/TV/program-list',
      // CREATE: '/TV/program-setup/create',
      // EDIT: '/TV/program-setup/edit',
    },
    TVCLIPS: {
      LIST: '/TV/video-clips',
      CREATE: '/TV/video-clips/create',
      EDIT: '/TV/video-clips/edit',
    },
    TVADS: {
      LIST: '/TV-Ads/overview',
      CREATE: '/TV-Ads/overview/create',
      EDIT: '/TV-Ads/overview/edit',
    },
  },
  AUTHORS: {
    OVERVIEW: {
      LIST: '/authors/overview',
      PROFILE: '/authors/overview/profile',
      CREATE: '/authors/overview/create',
      EDIT: '/authors/overview/edit',
    },
  },
  WEBSITEGARDEN: {
    LIST: {
      LIST: '/website-garden/overview',
      CREATE: '/website-garden/overview/add-website',
      EDIT: '/website-garden/overview/edit',
    },
  },
  API_FEED: { OVERVIEW: { OVERVIEW: '/web-syndication/overview' } },
  INFLUENCERS: {
    LIST: {
      LIST: '/influencers/overview',
      CREATE: '/influencers/overview/create-new-influencer',
      EDIT: '/influencers/overview/edit',
    },
  },
  SOCIALMEDIA: {
    POSTS: {
      LIST: '/posts/overview',
      CREATE: '/posts/overview/create-post',
      EDIT: '/posts/overview/edit',
    },
  },
  MONITORING: {
    FB_DOWNLOADER: {
      LIST: '/monitoring/facebook-downloader',
      CREATE: '/monitoring/facebook-downloader/create',
      EDIT: '/monitoring/facebook-downloader/edit',
    },
    MAILER: '/monitoring/mailer',
    CORPUS: '/monitoring/corpus',
    AV_MACHINES: {
      LIST: '/monitoring/av-machines',
      CREATE: '/monitoring/av-machines/create',
      EDIT: '/monitoring/av-machines/edit',
    },
    KEYWORDS: '/monitoring/keywords',
  },
  MAILER: '/mailer',
};
